const greeting = [
  {
    path: '/greeting-headdepartment',
    name: 'greeting-headdepartment',
    component: () => import('@/views/headdepartment/greeting/Greeting.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'GreetingHeadDepartment',
    },
  },
]
export default greeting
