const bankQuestion = [
  {
    path: '/bank-superadmin',
    name: 'bank-superadmin',
    component: () => import('@/views/superadmin/bank-question/BankQuestion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionSuperadmin',
    },
  },
  {
    path: '/bank-superadmin/detail/:uuid',
    name: 'bank-superadmin-detail',
    component: () => import('@/views/superadmin/bank-question/BankQuestionDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionSuperadmin',
    },
  },
  {
    path: '/bank-superadmin/add',
    name: 'bank-superadmin-add',
    component: () => import('@/views/superadmin/bank-question/BankQuestionAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionSuperadmin',
    },
  },
  {
    path: '/bank-superadmin/edit/:uuid',
    name: 'bank-superadmin-edit',
    component: () => import('@/views/superadmin/bank-question/BankQuestionEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionSuperadmin',
    },
  },
]
export default bankQuestion
