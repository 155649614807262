import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/export-attendance/employee-all'
const resource2 = '/api/v1/export-attendance/employee'

export default {

  exports(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/vnd.ms-excel',
    })
  },
  export(uuid,params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource2}/${uuid}${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/vnd.ms-excel',
    })
  },
}
