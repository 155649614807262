const headdepartment = [
  {
    path: '/headdepartment-superadmin',
    name: 'headdepartment-superadmin',
    component: () => import('@/views/superadmin/headdepartment/Headdepartment.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'HeaddepartmentSuperadmin',
    },
  },
]
export default headdepartment
