import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import announcement from './announcement'
import apps from './app'
import attendance from './attendance'
import bankCategory from './bankcategory'
import bankIcon from './bankicon'
import bankQuestion from './bankquestion'
import cbt from './cbt'
import dashboard from './dashboard'
import functionary from './functionary'
import greeting from './greeting'
import headbranch from './headbranch'
import headdepartment from './headdepartment'
import library from './library'
import locations from './location'
import major from './major'
import news from './news'
import packet from './packet'
import sarpras from './sarpras'
import school from './school'
import slider from './slider'
import student from './student'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const user = JSON.parse(localStorage.getItem('user'))
      const userRole = user && user.role ? user.role : null

      if (user?.user?.role === 'admin') return { name: 'dashboard-superadmin' }
      if (user?.user?.role === 'school') return { name: 'dashboard-school' }
      if (user?.user?.role === 'teacher') return { name: 'dashboard-teacher' }
      if (user?.user?.role === 'headmaster') return { name: 'dashboard-headmaster' }
      if (user?.user?.role === 'headdepartment') return { name: 'dashboard-headdepartment' }
      if (user?.user?.role === 'headbranch') return { name: 'dashboard-headbranch' }

      return { name: 'dashboard-school', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/misc-not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/request-account-deletion',
    name: 'request-account-deletion',
    component: () => import('@/views/DeleteAccount.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/success-verification',
    name: 'success-verification',
    component: () => import('@/views/SuccessVerification.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pivot',
    name: 'pivot',
    component: () => import('@/views/components/DataTablePaginationPivot.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...apps,
  ...attendance,
  ...announcement,
  ...bankIcon,
  ...bankCategory,
  ...bankQuestion,
  ...cbt,
  ...dashboard,
  ...functionary,
  ...headbranch,
  ...headdepartment,
  ...greeting,
  ...locations,
  ...library,
  ...major,
  ...news,
  ...packet,
  ...school,
  ...slider,
  ...student,
  ...sarpras,
  // ...schoolData,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const publicPages = [
    'auth-login',
    // 'auth-register',
    'auth-reset-password',
    'auth-forgot-password',
    'success-verification',
    'pivot',
  ]
  const authRequired = !publicPages.includes(to.name)
  const user = localStorage.getItem('user')
  const ability = JSON.parse(localStorage.getItem('userAbility'))
  const isLoggedIn = user && ability

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (authRequired && !isLoggedIn) return next('/login')

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
