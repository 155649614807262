const headbranch = [
  {
    path: '/headbranch-headdepartment',
    name: 'headbranch-headdepartment',
    component: () => import('@/views/headdepartment/headbranch/Headbranch.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'HeadbranchHeadDepartment',
    },
  },

]
export default headbranch
