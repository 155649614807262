const attendance = [
  {
    path: '/attendance-admin',
    name: 'attendance-admin',
    component: () => import('@/views/admin/attendance/Attendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AttendanceAdmin',
    },
  },
  {
    path: '/attendance-teacher',
    name: 'attendance-teacher',
    component: () => import('@/views/teacher/attendance/Attendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AttendanceTeacher',
    },
  },
  {
    path: '/attendance-headmaster',
    name: 'attendance-headmaster',
    component: () => import('@/views/headmaster/attendance/Attendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AttendanceHeadMaster',
    },
  },
]
export default attendance
