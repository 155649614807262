const student = [
  {
    path: '/student-admin',
    name: 'student-admin',
    component: () => import('@/views/admin/student/Student.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'StudentAdmin',
    },
  },
  {
    path: '/student-headmaster',
    name: 'student-headmaster',
    component: () => import('@/views/headmaster/student/Student.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'StudentHeadMaster',
    },
  },
]
export default student
