const school = [
  {
    path: '/school-superadmin',
    name: 'school-superadmin',
    component: () => import('@/views/superadmin/school/School.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolSuperadmin',
    },
  },
  {
    path: '/school-admin',
    name: 'school-admin',
    component: () => import('@/views/admin/school/School.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolAdmin',
    },
  },
  {
    path: '/school-headdepartment',
    name: 'school-headdepartment',
    component: () => import('@/views/headdepartment/school/School.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
  {
    path: '/school-headdepartment/detail/:uuid',
    name: 'school-headdepartment-detail',
    component: () => import('@/views/headdepartment/school/SchoolDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
  {
    path: '/school-headdepartment/detail/student/:uuid',
    name: 'school-headdepartment-detail-student',
    component: () => import('@/views/headdepartment/school/SchoolDetailStudent.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
  {
    path: '/school-headdepartment/detail/teacher/:uuid',
    name: 'school-headdepartment-detail-teacher',
    component: () => import('@/views/headdepartment/school/SchoolDetailTeacher.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
  {
    path: '/school-headdepartment/school/student-attendance/:uuid',
    name: 'school-headdepartment-student-attendance',
    component: () => import('@/views/headdepartment/school/SchoolDetailStudentAttendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
  {
    path: '/school-headdepartment/school/teacher-attendance/:uuid',
    name: 'school-headdepartment-teacher-attendance',
    component: () => import('@/views/headdepartment/school/SchoolDetailTeacherAttendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
  {
    path: '/school-headdepartment/detail/sarpras/:uuid',
    name: 'school-headdepartment-detail-sarpras',
    component: () => import('@/views/headdepartment/school/SchoolDetailSarpras.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
  {
    path: '/school-headdepartment/detail/sarpras-rehab/:uuid',
    name: 'school-headdepartment-detail-sarpras-rehab',
    component: () => import('@/views/headdepartment/school/SchoolDetailSarprasRehab.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolHeadDepartment',
    },
  },
]
export default school
