const major = [
  {
    path: '/major-superadmin',
    name: 'major-superadmin',
    component: () => import('@/views/superadmin/major/Major.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'MajorSuperadmin',
    },
  },
]
export default major
