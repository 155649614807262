const library = [
  {
    path: '/library-superadmin',
    name: 'library-superadmin',
    component: () => import('@/views/superadmin/library/Library.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'LibrarySuperadmin',
    },
  },
]
export default library
