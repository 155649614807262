const bankIcon = [
  {
    path: '/bank-icon-superadmin',
    name: 'bank-icon-superadmin',
    component: () => import('@/views/superadmin/bank-icon/BankIcon.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankIconSuperadmin',
    },
  },
]
export default bankIcon
