import repo from '@/repositories'

const repositories = {
  ability: repo.AbilityRepository,
  answer: repo.AnswerRepository,
  archivequestion: repo.ArchiveQuestionRepository,
  archivelesson: repo.ArchiveLessonRepository,
  archivebank: repo.ArchiveBankRepository,
  attendance: repo.AttendanceRepository,
  attendanceemployee: repo.AttendanceEmployeeRepository,
  auth: repo.AuthRepository,
  bankicon: repo.BankIconRepository,
  bankiconpost: repo.BankIconPostRepository,
  bankcategory: repo.BankCategoryRepository,
  calendar: repo.CalendarRepository,
  category: repo.CategoryRepository,
  cbt: repo.CbtRepository,
  company: repo.CompanyRepository,
  chapter: repo.ChapterRepository,
  class: repo.ClassRepository,
  changeclass: repo.ChangeClassRepository,
  district: repo.DistrictRepository,
  employee: repo.EmployeeRepository,
  exercise: repo.ExerciseRepository,
  exam: repo.ExamRepository,
  faq: repo.FaqRepository,
  fcm: repo.FcmRepository,
  greeting: repo.GreetingRepository,
  greetingheaddepartment: repo.GreetingHeadDepartmentRepository,
  hours: repo.HoursRepository,
  hob: repo.HeadBranchRepository,
  level: repo.LevelRepository,
  library: repo.LibraryRepository,
  librarycategory: repo.LibraryCategoryRepository,
  libraryfavorite: repo.LibraryFavoriteRepository,
  librarypurchase: repo.LibraryPurchaseRepository,
  libraryschool: repo.LibrarySchoolRepository,
  lesson: repo.LessonRepository,
  major: repo.MajorRepository,
  mutation: repo.MutationRepository,
  mutationgrade: repo.MutationGradeRepository,
  mutationmany: repo.MutationManyRepository,
  packageschool: repo.PackageSchoolRepository,
  packet: repo.PacketRepository,
  packetlibrary: repo.PacketLibraryRepository,
  packetorder: repo.PacketOrderRepository,
  picketschedule: repo.PicketScheduleRepository,
  province: repo.ProvinceRepository,
  promotion: repo.PromotionRepository,
  publisher: repo.PublisherRepository,
  question: repo.QuestionRepository,
  questioncbt: repo.QuestionCbtRepository,
  questionbank: repo.QuestionBankRepository,
  questioncontent: repo.QuestionBankContentRepository,
  qrcodeuser: repo.QrCodeUserRepository,
  qrcodeclass: repo.QrCodeClassRepository,
  regency: repo.RegencyRepository,
  slider: repo.SliderRepository,
  sliderheaddepartment: repo.SliderHeadDepartmentRepository,
  subCategory: repo.SubcategoryRepository,
  subsubCategory: repo.SubsubcategoryRepository,
  section: repo.SectionRepository,
  subject: repo.SubjectRepository,
  tag: repo.TagRepository,
  user: repo.UserRepository,
  village: repo.VillageRepository,
  writer: repo.WriterRepository,
  evoting: repo.EvotingRepository,
  eraport: repo.EraportRepository,
  candidate: repo.CandidateRepository,
  ranking: repo.RankingRepository,
  resultevoting: repo.ResultEvotingRepository,
  forumtopic: repo.ForumTopicRepository,
  forum: repo.ForumRepository,
  commentforum: repo.CommentForumRepository,
  likeforum: repo.LikeForumRepository,
  bookmark: repo.BookmarkForumRepository,
  violation: repo.ViolationRepository,
  violationstudent: repo.ViolationStudentRepository,
  violationemployee: repo.ViolationEmployeeRepository,
  violationforum: repo.ViolationForumRepository,
  violationsetting: repo.ViolationSettingRepository,
  news: repo.NewsRepository,
  announcement: repo.AnnouncementRepository,
  announcementhod: repo.AnnouncementHodRepository,
  announcementheadmaster: repo.AnnouncementHeadmasterRepository,
  schedule: repo.ScheduleRepository,
  dashboardadmin: repo.DashboardAdminRepository,
  dashboardsuperadmin: repo.DashboardSuperadminRepository,
  dashboardteacher: repo.DashboardTeacherRepository,
  dashboardheaddepartment: repo.DashboardHeaddepartmentRepository,
  dashboardheadmaster: repo.DashboardHeadmasterRepository,
  certificate: repo.CertificateRepository,
  student: repo.StudentRepository,
  studentheadmaster: repo.StudentHeadMasterRepository,
  studentactive: repo.StudentActiveRepository,
  schoolfund: repo.SchoolFundRepository,
  school: repo.SchoolRepository,
  sarpras: repo.SarprasRepository,
  sarprastool: repo.SarprasToolRepository,
  sarprasrehab: repo.SarprasRehabRepository,
  schoolactive: repo.SchoolActiveRepository,
  teacher: repo.TeacherRepository,
  teacheractive: repo.TeacherActiveRepository,
  headdepartment: repo.HeadDepartmentRepository,
  newshod: repo.NewsHodRepository,
  questionbankhod: repo.QuestionBankHodRepository,
  commentlesson: repo.CommentLessonRepository,
}

export default {
  async list(service, params = {}) {
    try {
      return repositories[service.toLowerCase()].list(params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no list method` })
    }
  },
  async listParams(service, uuid, params = {}) {
    try {
      return repositories[service.toLowerCase()].list(params, uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no list method` })
    }
  },
  add(service, data) {
    try {
      return repositories[service.toLowerCase()].add(data).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has add method` })
    }
  },
  get(service, uuid) {
    try {
      return repositories[service.toLowerCase()].get(uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no get method` })
    }
  },
  getParams(service, uuid, params = {}) {
    try {
      return repositories[service.toLowerCase()].getParams(uuid, params).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no list method` })
    }
  },
  update(service, data, uuid) {
    try {
      return repositories[service.toLowerCase()].update(data, uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no update method` })
    }
  },
  destroy(service, uuid) {
    try {
      return repositories[service.toLowerCase()].destroy(uuid).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no delete method` })
    }
  },
  delete(service, data) {
    try {
      return repositories[service.toLowerCase()].delete(data).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has add method` })
    }
  },
  deleteStorage(service, image) {
    try {
      return repositories[service.toLowerCase()].deleteStorage(image).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service doesn't has add method` })
    }
  },

  checkToken(service, uuid, data) {
    try {
      return repositories[service.toLowerCase()].checkToken(uuid, data).then(
        res => Promise.resolve(res),
        err => Promise.reject(err),
      )
    } catch (err) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: `Services error: ${service} service has no get method` })
    }
  },
}
