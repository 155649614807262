const news = [
  {
    path: '/news-superadmin',
    name: 'news-superadmin',
    component: () => import('@/views/superadmin/news/News.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'NewsSuperadmin',
    },
  },
  {
    path: '/news-headdepartment',
    name: 'news-headdepartment',
    component: () => import('@/views/headdepartment/news/News.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'NewsHeadDepartment',
    },
  },
]
export default news
