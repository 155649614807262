<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="user.user.details[0].image || user.user.details[0].photo"
            :src="user.user.details[0].image || user.user.details[0].photo"
          ></v-img>
          <v-img
            v-else
            src="@/assets/images/avatars/ari.png"
          ></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="user.user.details[0].image || user.user.details[0].photo"
              :src="user.user.details[0].image || user.user.details[0].photo"
            ></v-img>
            <v-img
              v-else
              src="@/assets/images/avatars/ari.png"
            ></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span
            class="text--primary font-weight-semibold mb-n1 d-inline-block text-truncate"
            style="max-width: 140px;"
          >
            {{ user.user.name }}
          </span>
          <small class="text--disabled text-capitalize">{{ user.user.role }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'apps-user-view', params: user.user.role === 'admin' ? { uuid: userUuid } : { uuid: userDetail.uuid } }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance, ref, onMounted } from '@vue/composition-api'

export default {
  setup(props, context) {
    const abilityDefault = [
      {
        action: 'read',
        subject: 'Public',
      },
      {
        action: 'read',
        subject: 'AppUserView',
      },
      {
        action: 'read',
        subject: 'AppUserSettings',
      },
      {
        action: 'read',
        subject: 'AppUserFaq',
      },
      {
        action: 'read',
        subject: 'AppUserNotification',
      },
    ]
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const user = JSON.parse(localStorage.getItem('user'))
    const userUuid = ref('')
    const role = ref('')
    const userDetail = ref([])
    const getUserDetail = () => {
      if (user.user.role !== 'admin') {
        userDetail.value = JSON.parse(localStorage.getItem('user')).user.details
        userDetail.value.forEach(user => {
          userDetail.value.uuid = user.uuid
        })
      } else {
        userUuid.value = JSON.parse(localStorage.getItem('user')).user.uuid
      }
    }
    const logoutUser = async () => {
      // Remove user from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove user & Ability from localStorage
      localStorage.removeItem('user')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(abilityDefault)
      // Redirect to login page
      router.push({ name: 'auth-login' })
      await context.root.$services.AuthServices.logout().then(
        ({ data }) => {},
        err => {
          console.error(err)
        },
      )
    }
    onMounted(() => {
      getUserDetail()
    })

    return {
      logoutUser,
      user,
      userUuid,
      userDetail,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        role,
        getUserDetail,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
