const location = [
  {
    path: '/location-superadmin',
    name: 'location-superadmin',
    component: () => import('@/views/superadmin/location/Location.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'LocationSuperadmin',
    },
  },
]

export default location
