const announcement = [

  {
    path: '/announcement-headdepartment',
    name: 'announcement-headdepartment',
    component: () => import('@/views/headdepartment/announcement/Announcement.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AnnouncementHeadDepartment',
    },
  },
  {
    path: '/announcement-headmaster',
    name: 'announcement-headmaster',
    component: () => import('@/views/headmaster/announcement/Announcement.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AnnouncementHeadMaster',
    },
  },
]

export default announcement
