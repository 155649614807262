const functionary = [
  {
    path: '/functionary-admin',
    name: 'functionary-admin',
    component: () => import('@/views/admin/functionary/Functionary.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'FunctionaryAdmin',
    },
  },
  {
    path: '/functionary-headmaster',
    name: 'functionary-headmaster',
    component: () => import('@/views/headmaster/functionary/Functionary.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'FunctionaryHeadMaster',
    },
  },
]
export default functionary
