import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/sarpras-tool'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(id) {
    return http.get(`${resource}/${id}`)
  },
  update(sarprasTool, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, sarprasTool, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  add(sarprasTool) {
    return http.post(`${resource}`, sarprasTool, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  destroy(id) {
    return http.delete(`${resource}/${id}`)
  },
}
