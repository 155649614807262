const packet = [
  {
    path: '/packet-superadmin',
    name: 'packet-superadmin',
    component: () => import('@/views/superadmin/packet/Packet.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'PacketSuperadmin',
    },
  },
]
export default packet
