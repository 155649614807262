const sarpras = [
  {
    path: '/sarpras-admin',
    name: 'sarpras-admin',
    component: () => import('@/views/admin/sarpras/SarprasData.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SarprasAdmin',
    },
  },
  {
    path: '/sarpas-admin/:uuid/tool',
    name: 'sarpras-admin-tool',
    component: () => import('@/views/admin/sarpras/SarprasTool.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SarprasAdmin',
    },
  },
  {
    path: '/sarpas-admin/:uuid/rehab',
    name: 'sarpras-admin-rehab',
    component: () => import('@/views/admin/sarpras/SarprasRehab.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SarprasAdmin',
    },
  },
  {
    path: '/sarpras-headmaster',
    name: 'sarpras-headmaster',
    component: () => import('@/views/headmaster/sarpras/Sarpras.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SarprasHeadMaster',
    },
  },
]
export default sarpras
