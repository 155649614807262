const cbt = [
  {
    path: '/cbt-superadmin',
    name: 'cbt-superadmin',
    component: () => import('@/views/superadmin/cbt/Cbt.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSuperadmin',
    },
  },
  {
    path: '/cbt-superadmin/add',
    name: 'cbt-add-admin',
    component: () => import('@/views/superadmin/cbt/CbtAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSuperadmin',
    },
  },
  {
    path: '/cbt-superadmin/:uuid/edit',
    name: 'cbt-edit-admin',
    component: () => import('@/views/superadmin/cbt/CbtEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSuperadmin',
    },
  },
  // {
  //   path: '/cbt-superadmin/:uuid/cbt-and-result-admin',
  //   name: 'cbt-and-result-admin',
  //   component: () => import('@/views/superadmin/cbt/cbtAndResult.vue'),
  //   meta: {
  //     layout: 'content',
  //     action: 'read',
  //     resource: 'CbtSuperadmin',
  //   },
  // },
  // {
  //   path: '/cbt-headdepartment',
  //   name: 'cbt-headdepartment',
  //   component: () => import('@/views/headdepartment/cbt/Cbt.vue'),
  //   meta: {
  //     layout: 'content',
  //     action: 'read',
  //     resource: 'CbtHeadDepartment',
  //   },
  // },
]
export default cbt
