const slider = [
  {
    path: '/slider-superadmin',
    name: 'slider-superadmin',
    component: () => import('@/views/superadmin/slider/Slider.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SliderSuperadmin',
    },
  },
  // {
  //   path: '/slider-admin',
  //   name: 'slider-admin',
  //   component: () => import('@/views/admin/slider/Slider.vue'),
  //   meta: {
  //     layout: 'content',
  //     action: 'read',
  //     resource: 'SliderSchool',
  //   },
  // },
  {
    path: '/slider-headdepartment',
    name: 'slider-headdepartment',
    component: () => import('@/views/headdepartment/slider/Slider.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SliderHeadDepartment',
    },
  },
]
export default slider
