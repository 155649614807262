const dashboard = [
  {
    path: '/dashboard-school',
    name: 'dashboard-school',
    component: () => import('@/views/admin/dashboard/Admin.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardSchool',
    },
  },
  {
    path: '/dashboard-superadmin',
    name: 'dashboard-superadmin',
    component: () => import('@/views/superadmin/dashboard/Superadmin.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/book-puchase-history',
    name: 'book-purchase-history',
    component: () => import('@/views/superadmin/dashboard/BookPurchaseHistory.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/most-active-school',
    name: 'most-active-school',
    component: () => import('@/views/superadmin/dashboard/MostActiveSchool/MostActiveSchool.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/most-active-school/:uuid/detail',
    name: 'most-active-school-detail',
    component: () => import('@/views/superadmin/dashboard/MostActiveSchool/MostActiveSchoolDetail.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/most-active-school/:uuid/detail-book-purchase',
    name: 'most-active-school-detail-book-purchase',
    component: () => import('@/views/superadmin/dashboard/MostActiveSchool/MostActiveSchoolDetailBookPurchase.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/most-active-school/:uuid/detail-active-student',
    name: 'most-active-school-detail-active-student',
    component: () => import('@/views/superadmin/dashboard/MostActiveSchool/MostActiveSchoolDetailActiveStudent.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/most-active-school/:uuid/detail-active-teacher',
    name: 'most-active-school-detail-active-teacher',
    component: () => import('@/views/superadmin/dashboard/MostActiveSchool/MostActiveSchoolDetailActiveTeacher.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/most-active-teacher',
    name: 'most-active-teacher',
    component: () => import('@/views/superadmin/dashboard/MostActiveTeacher/MostActiveTeacher.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/most-active-student',
    name: 'most-active-student',
    component: () => import('@/views/superadmin/dashboard/MostActiveStudent/MostActiveStudent.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-superadmin/favorite-book',
    name: 'favorite-book',
    component: () => import('@/views/superadmin/dashboard/FavoriteBook.vue'),
    meta: {
      layout: 'content',
      resource: 'DashboardSuperadmin',
      action: 'read',
    },
  },
  {
    path: '/dashboard-teacher',
    name: 'dashboard-teacher',
    component: () => import('@/views/teacher/dashboard/Teacher.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardTeacher',
    },
  },
  {
    path: '/dashboard-headmaster',
    name: 'dashboard-headmaster',
    component: () => import('@/views/headmaster/dashboard/Headmaster.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardHeadMaster',
    },
  },
  {
    path: '/dashboard-headmaster/announcement',
    name: 'dashboard-headmaster-announcement',
    component: () => import('@/views/headmaster/dashboard/Announcement.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardHeadMaster',
    },
  },
  {
    path: '/dashboard-headdepartment',
    name: 'dashboard-headdepartment',
    component: () => import('@/views/headdepartment/dashboard/Headdepartment.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardHeadDepartment',
    },
  },
  {
    path: '/dashboard-headbranch',
    name: 'dashboard-headbranch',
    component: () => import('@/views/headbranch/dashboard/Headbranch.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'DashboardHeadBranch',
    },
  },
]
export default dashboard
