import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
// import '@/styles/app.css'
import '@/styles/styles.scss'
import { abilitiesPlugin } from '@casl/vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
// import '@fortawesome/fontawesome-free/css/all.min.css'
import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import VueSocialauth from 'vue-social-auth'
import App from './App.vue'
import firebaseMessaging from './firebase'
import ability from './plugins/acl/ability'
import vuetify from './plugins/vuetify'
import router from './router'
import services from './services'
import store from './store'

Vue.prototype.$messaging = firebaseMessaging
Vue.prototype.$services = services
Vue.config.productionTip = false
Vue.use(CKEditor)
Vue.use(InfiniteLoading)
Vue.use(abilitiesPlugin, ability)
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_SOCIAL_GOOGLE_CLIENT_ID,
      redirectUri: 'http://localhost:8080/login', // Your client app URL
      popupOptions: {
        top: 120,
        left: window.innerWidth,
        width: 500,
      },
    },
  },
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  ability,
  render: h => h(App),
}).$mount('#app')
